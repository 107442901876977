<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>兑换管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input v-model="searchForm.userName" placeholder="用户名称"></el-input>
        </div>
        <div class="input_box">
          <el-input v-model="searchForm.productName" placeholder="商品名称"></el-input>
        </div>
        <div class="input_box">
          <el-select v-model="searchForm.productType" clearable collapse-tags placeholder="商品类型">
            <el-option v-for="item in productTypeList" :key="item.id" :label="item.type" :value="item.id">
            </el-option>
          </el-select>
        </div><!-- v-if="this.$store.state.role === 'admin'" -->
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <!--          <el-select v-model="searchForm.communityIds" multiple clearable placeholder="社区">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>-->
          <el-select v-model="searchForm.communityIds" multiple collapse-tags style="margin-left: 1.25rem;"
            placeholder="社区">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchTable"></el-button></div>
        <div class="input_box"><el-button type="primary" class="el-icon-sold-out" @click="exchange">兑换</el-button></div>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column fixed prop="id" label="序号" width="50" align="center" />
        <el-table-column prop="userName" label="兑换用户" width="120" align="center" />
        <el-table-column prop="productName" label="商品名称" width="220" align="center" />
        <el-table-column prop="productTypeName" label="商品类型" width="100" align="center" />
        <el-table-column prop="realMoney" label="实际金额(元)" width="100" align="center" />
        <el-table-column prop="costScore" label="兑换积分" width="100" align="center" />
        <el-table-column prop="payable" label="待付金额" width="100" align="center" />
        <el-table-column prop="payment" label="支付方式" width="120" align="center">
          <template slot-scope="scope">
            <el-tag size="medium" v-if="scope.row.payment == 0">纯积分</el-tag>
            <el-tag size="medium" v-if="scope.row.payment == 1" type="warning">纯金额</el-tag>
            <el-tag size="medium" v-if="scope.row.payment == 2" type="success">积分+金额</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="exchangeNumber" label="兑换数量" width="80" align="center" />
        <el-table-column prop="exchangeStatus" label="是否兑换" width="120" align="center">
          <template slot-scope="scope">
            <el-tag size="medium" v-if="scope.row.exchangeStatus == '已兑现'" type="info">已兑现</el-tag>
            <el-tag size="medium" v-if="scope.row.exchangeStatus == '未兑现'" type="danger">未兑现</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="phoneNumber" label="联系电话" width="180" align="center" />
        <el-table-column prop="deliveryAddress" label="联系地址" width="280" align="center" />
        <el-table-column prop="createTime" label="兑换时间" width="200" align="center" />
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
    <!--兑换-->
    <el-dialog :visible.sync="exchangeDialog" width="50%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>商品核销
      </div>
      <exchange :key="timer" @closed="closed"></exchange>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import Exchange from './components/exchange'
export default {
  name: 'index',
  components: { Exchange },
  data() {
    return {
      productTypeList: [],
      communityList: [],
      tableData: [],
      searchForm: {
        userName: "",
        productName: '',
        productType: '',
        communityId: '',
        communityIds: null
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      exchangeDialog: false,
      timer: ''
    }
  },
  created() {
    this.fetchUserPower()
    // this.getExchangeRecord(this.searchForm, this.pageBox)
    this.getCommuintyList()
    this.getProductList()
  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getExchangeRecord(this.searchForm, this.pageBox)
        }
      } catch (e) {

      }
    },
    //获取商品类型列表
    async getProductList() {
      let data = {
        type: ''
      }
      let page = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        let res = await api.getGoodsTypeList(data, page)
        if (res.code == 200) {
          this.productTypeList = res.rows
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    //获取社区列表
    async getCommuintyList(code) {
      let data = {
        code: code,
      }
      let page = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        let res = await api.getCommunitySystemUser(data, page)
        if (res.code == 200) {
          this.communityList = res.rows
        }
      } catch (e) {

      }
    },
    //获取兑换记录查询列表
    async getExchangeRecord(data, page) {
      try {
        let res = await api.getExchangeRecordList(data, page)
        if (res.code == 200) {
          console.log(res);
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getExchangeRecord(this.searchForm, this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getExchangeRecord(this.searchForm, this.pageBox)
    },
    //根据条件查询
    searchTable() {
      this.getExchangeRecord(this.searchForm, this.pageBox)
    },
    // 兑换
    exchange() {
      this.timer = new Date().getTime()
      this.exchangeDialog = true
    },
    //关闭弹窗
    closed(val) {
      console.log(val)//接受子组件的传值
      this.exchangeDialog = false
      this.getExchangeRecord(this.searchForm, this.pageBox)
    }
  },
  watch: {}
}
</script>

<style scoped>
::v-deep .el-select__tags {
  max-width: none !important;
}

::v-deep .el-select__tags-text {
  display: inline-block;
  max-width: 6rem !important;
}
</style>
