<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="兑换码" prop="exchangeCode" placeholder="请输入兑换码">
        <el-input v-model="ruleForm.exchangeCode"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">核销</el-button>
        <el-button @click="cancelFrom('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/index'
import debounce from 'lodash/debounce';
export default {
  name: 'exchange',
  data() {
    return {
      communityIds: null,
      ruleForm: {
        exchangeCode: '',
      },
      rules: {
        exchangeCode: [
          { required: true, message: '请输入兑换码', trigger: 'blur' },
        ]
      },
      exchangeData: null
    }
  },
  created() {
    this.fetchUserPower()
  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.communityIds = res.data.comId
          }
        }
      } catch (e) {

      }
    },
    //根据兑换码查询兑换记录
    async getCode(exchangeCode) {
      try {
        console.log(exchangeCode);
        // console.log(encodeURIComponent(data));
        let res = await api.getExchangeCode(exchangeCode, { communityIds: this.communityIds })
        this.exchangeData = res.data
        console.log("resresr", res);
        if (res.code == 200) {
          this.$message.success('核销成功')
          this.$emit('closed')
          this.sendProductRecoder()
        } else {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        console.log(e);
        this.$message.error(e.msg)
      }
    },
    submitForm: debounce(async function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getCode(this.ruleForm.exchangeCode)
        } else {
          return false;
        }
      });
    }, 500),
    cancelFrom(formName) {
      this.$emit('closed')
    },
    sendProductRecoder() {
      const data = {
        doctorId: this.$store.state.userId,
        userId: this.exchangeData.userId,
        userName: this.exchangeData.userName,
        productName: this.exchangeData.productName,
        msg: "用户购买了您发布的商品",
        reservationTime: this.currentTime(),
        productType: this.exchangeData.productType,
        productId: this.exchangeData.productId,
      }
      api.addReserveMsg(data)
        .then(res => {
          if (res.code === 200) {
            console.log(res)
          } else {
            console.log(res)
          }
        }).catch(res => {
          this.$refs.paging.complete(false)
        })

    },
    //获取当前时间
    currentTime() {
      var date = new Date();
      var year = date.getFullYear(); //月份从0~11，所以加一
      let month = date.getMonth();
      console.log("month", month);
      var dateArr = [
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      ];
      //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
      for (var i = 0; i < dateArr.length; i++) {
        if (dateArr[i] >= 1 && dateArr[i] <= 9) {
          dateArr[i] = "0" + dateArr[i];
        }
      }
      var strDate =
        year +
        "-" +
        dateArr[0] +
        "-" +
        dateArr[1] +
        " " +
        dateArr[2] +
        ":" +
        dateArr[3] +
        ":" +
        dateArr[4];
      //此处可以拿外部的变量接收  strDate:2022-05-01 13:25:30
      //this.date = strDate;

      return strDate
      console.log(strDate)
    },
  }
}
</script>

<style scoped></style>
